<template>
  <div>
    <el-dialog
      :title="title"
      :visible.sync="dialogVisible"
      width="900px"
      :before-close="handleClose"
    >
      <iframe
        v-if="type == 'pdf'"
        :src="url"
        frameborder="0"
        width="100%;"
        height="500"
      ></iframe>
      <viewer v-if="type == 'image'">
        <img :src="url" width="100%;" />
      </viewer>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "Preview",

  data() {
    return {
      dialogVisible: false,
      url: "",
      title: "",
      type: "",
    };
  },

  mounted() {},

  methods: {
    //打开弹窗
    open(type, url, title) {
      this.type = type;
      this.title = title ? title : "";
      this.url = url;
      this.dialogVisible = true;
    },
    //关闭前
    handleClose() {
      this.dialogVisible = false;
    },
  },
};
</script>

<style scoped>
</style>