<template>
	<div class="detail-box">
		<!-- 顶部 -->
		<div class="top-box">
			<div class="top-title">{{title}}-展开邮件</div>
			<div class="top-button-box">
				<div class="top-button-item" @click="back">
					{{ "<<" }}
				</div>
				<div v-if="title === '收件箱'" class="top-button-item" @click="handleReply">回复</div>
				<div class="top-button-item" @click="handleTranspond">转发</div>
			</div>
		</div>
		<!-- 内容 -->
		<div class="container" v-if="detail">
			<!-- 邮件标题 -->
			<div class="container-title">
				{{ detail.subject || "" }}
			</div>
			<!-- 邮件收发 -->
			<div class="container-user">
				<div class="container-user-item">
					发件人：{{ detail.sendMemberMail || "" }}
				</div>
				<div class="container-user-item">
					收件人：{{ detail.receiveMemberMail || "" }}
				</div>
				<div class="container-user-item">
					时间：{{ detail.createTime || "" }}
				</div>
			</div>
			<!-- 内容-内容 -->
			<div class="container-content">
				{{ detail.sourceContent || "" }}
			</div>
			<!-- 附件 -->
			<div class="attached-file">
				<div class="attached-file-title">相关附件</div>
				<!-- 附件列表 -->
				<div class="attached-file-list" v-if="detail.memberMailAppendices.length > 0">
					<div class="attached-file-list-item" v-for="(item, index) in detail.memberMailAppendices" :key="index">
						<img src="@/assets/images/fujian1.png" alt="" class="attached-file-list-item-image" />
						<div class="attached-file-list-item-name">
							{{ item.appendixName || "" }}
						</div>
						<div class="attached-file-list-item-button orange-text" @click="preview(item)">
							预览
						</div>
						<div class="attached-file-list-item-button blue-text" @click="download(item)">下载</div>
					</div>
				</div>
			</div>
		</div>
		<!-- 底部 -->
		<div class="bottom-box">
			<div class="top-button-item" @click="back">
				{{ "<<" }}
			</div>
			<div v-if="title === '收件箱'" class="top-button-item" @click="handleReply">回复</div>
			<div class="top-button-item" @click="handleTranspond">转发</div>
		</div>
		<!-- pdf预览 -->
		<preview ref="preview"></preview>
		<!-- 图片预览 -->
	</div>
</template>

<script>
	import preview from '@/components/preview'

	export default {
		name: "EmailDetail",
		components: {
			preview
		},
		data() {
			return {
				id: "", //邮件id
				detail: null, //详情信息
				title: '',
			};
		},
		created() {
			if (this.$route.query.id) {
				this.id = this.$route.query.id;
				this.title = this.$route.query.title
				this.getEmailDetail();
			}
		},
		mounted() {},
		methods: {
			//返回上一页
			back() {
				this.$router.go(-1)
			},
			// 回复
			handleReply() {
				this.$router.push({
					path: 'sendEmails',
					query: {
						id: this.id
					}
				});
			},
			// 转发
			handleTranspond() {
				// this.$router.push({ path: 'sentItemsTranspond' })
				this.$router.push({
					path: "sendEmails",
					query: {
						model: JSON.stringify(this.detail)
					},
				});
			},
			//获取邮件详情
			getEmailDetail() {
				this.$api.email.emailDetail({
					id: this.id,
				}).then((res) => {
					console.log("邮件详情", res.result);
					this.detail = res.result;
				});
			},
			//下载
			download(val) {
				let name = this.getFileType(val.appendixName)
				if (name == 'video' || name == 'pdf') {
					this.downLoadFile(val.url, val.appendixName)
				} else {
					window.open(val.url)
				}
			},
			//下载文件
			downLoadFile(url, name) {
				var xhr = new XMLHttpRequest();
				xhr.open('GET', url, true);
				xhr.responseType = 'arraybuffer'; // 返回类型blob
				xhr.onload = function() {
					if (xhr.readyState === 4 && xhr.status === 200) {
						let blob = this.response;
						console.log(blob);
						// 转换一个blob链接
						// 注: URL.createObjectURL() 静态方法会创建一个 DOMString(DOMString 是一个UTF-16字符串)，
						// 其中包含一个表示参数中给出的对象的URL。这个URL的生命周期和创建它的窗口中的document绑定
						let downLoadUrl = window.URL.createObjectURL(new Blob([blob], {
							type: 'video/mp4'
						}));
						// 视频的type是video/mp4，图片是image/jpeg
						// 01.创建a标签
						let a = document.createElement('a');
						// 02.给a标签的属性download设定名称
						a.download = name;
						// 03.设置下载的文件名
						a.href = downLoadUrl;
						// 04.对a标签做一个隐藏处理
						a.style.display = 'none';
						// 05.向文档中添加a标签
						document.body.appendChild(a);
						// 06.启动点击事件
						a.click();
						// 07.下载完毕删除此标签
						a.remove();
					};
				};
				xhr.send()
			},
			//预览文件
			preview(val) {
				console.log(val.appendixName);
				let name = this.getFileType(val.appendixName)
				//   console.log(name);
				//word,excel,ppt
				if (name == 'word' || name == 'excel' || name == 'ppt') {
					let router = encodeURIComponent(val.url) //文件路径let url = encodeURIComponent(routeUrl)
					let officeUrl = 'http://view.officeapps.live.com/op/view.aspx?src=' + router
					window.open(officeUrl, '_target')
				}
				if (name == 'pdf') {
					this.$refs.preview.open('pdf', val.url, val.appendixName)
				} else if (name == 'image') {
					this.$refs.preview.open('image', val.url, val.appendixName)
				} else if (name == 'video') {
					window.open(val.url)
				} else {
					this.$message({
						message: '暂不支持此类型预览，请下载后自行预览',
						type: 'warning'
					});
				}
			},
			//文件类型
			getFileType(fileName) {
				let suffix = ""; // 后缀获取
				let result = ""; // 获取类型结果
				if (fileName) {
					const flieArr = fileName.split("."); // 根据.分割数组
					suffix = flieArr[flieArr.length - 1]; // 取最后一个
				}
				if (!suffix) return false; // fileName无后缀返回false
				suffix = suffix.toLocaleLowerCase(); // 将后缀所有字母改为小写方便操作
				// 匹配图片
				const imgList = ["png", "jpg", "jpeg", "bmp", "gif"]; // 图片格式
				result = imgList.find((item) => item === suffix);
				if (result) return "image";
				// 匹配txt
				const txtList = ["txt"];
				result = txtList.find((item) => item === suffix);
				if (result) return "txt";
				// 匹配excel
				const excelList = ["xls", "xlsx"];
				result = excelList.find((item) => item === suffix);
				if (result) return "excel";
				// 匹配word
				const wordList = ["doc", "docx"];
				result = wordList.find((item) => item === suffix);
				if (result) return "word";
				// 匹配pdf
				const pdfList = ["pdf"];
				result = pdfList.find((item) => item === suffix);
				if (result) return "pdf";
				// 匹配ppt
				const pptList = ["ppt", "pptx"];
				result = pptList.find((item) => item === suffix);
				if (result) return "ppt";
				// 匹配zip
				const zipList = ["rar", "zip", "7z"];
				result = zipList.find((item) => item === suffix);
				if (result) return "zip";
				// 匹配视频
				const videoList = [
					"mp4",
					"m2v",
					"mkv",
					"rmvb",
					"wmv",
					"avi",
					"flv",
					"mov",
					"m4v",
				];
				result = videoList.find((item) => item === suffix);
				if (result) return "video";
				// 匹配音频
				const radioList = ["mp3", "wav", "wmv"];
				result = radioList.find((item) => item === suffix);
				if (result) return "radio";
				// 其他文件类型
				return "other";
			},
		},
	};
</script>

<style scoped>
	.detail-box {
		flex: 1;
		position: relative;
		height: 100%;
		border-left: 1px solid #dcdfe6;
		background: #fff;
	}

	.top-title {
		height: 59px;
		width: 100%;
		display: flex;
		align-items: center;
		padding-left: 20px;
		color: #313233;
		font-weight: 600;
		font-family: PingFangSC-Medium, PingFang SC;
		border-bottom: 1px solid #dcdfe6;
		background: #fff;
	}

	.top-button-box {
		width: 100%;
		display: flex;
		align-items: center;
		padding-left: 10px;
		border-bottom: 1px solid #dcdfe6;
		height: 56px;
		background: #fff;
	}

	.top-button-item {
		padding: 0 16px;
		height: 28px;
		line-height: 28px;
		border-radius: 14px;
		color: #313233;
		font-size: 16px;
		border: 1px solid #dcdfe6;
		margin-right: 10px;
		cursor: pointer;
	}

	.top-box {
		width: 100%;
		position: absolute;
		top: 0;
		left: 0;
	}

	.bottom-box {
		width: 100%;
		height: 68px;
		border-top: 1px solid #dcdfe6;
		position: absolute;
		left: 0;
		bottom: 0;
		display: flex;
		align-items: center;
		padding-left: 10px;
		background: #fff;
	}

	.container {
		position: absolute;
		width: 100%;
		top: 135px;
		bottom: 88px;
		left: 0;
		right: 0;
		overflow-x: hidden;
		overflow-y: scroll;
		padding: 0 20px;
	}

	.container-title {
		color: #313233;
		font-size: 14px;
		font-weight: 600;
	}

	.container-user {
		margin-top: 20px;
	}

	.container-user-item {
		font-size: 12px;
		color: #7b7d80;
		margin-bottom: 10px;
	}

	.container-content {
		margin-top: 10px;
		border: 1px solid #dcdfe6;
		padding: 20px;
		color: #313233;
		font-size: 14px;
		min-height: 320px;
		width: calc(100% - 80px);
		white-space: pre;
	}

	.attached-file {
		width: 100%;
		margin-top: 20px;
	}

	.attached-file-title {
		font-size: 12px;
		color: #313233;
		margin-bottom: 20px;
	}

	.attached-file-list-item {
		width: 100%;
		margin-bottom: 10px;
		display: flex;
		align-items: center;
	}

	.attached-file-list-item-image {
		width: 16px;
		height: 16px;
		flex-shrink: 0;
		margin-right: 4px;
	}

	.attached-file-list-item-name {
		color: #313233;
		font-size: 12px;
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
	}

	.attached-file-list-item-button {
		margin-left: 20px;
		font-size: 12px;
		cursor: pointer;
	}

	.orange-text {
		color: #ff6632;
	}

	.blue-text {
		color: #4db4d4;
	}
</style>